<template>
  <v-dialog v-model="isOpen" width="800" persistent>
    <v-form ref="form" v-model="valid" @submit.prevent="save">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-alert v-if="hasNonFieldErrors" type="error">
            <ul>
              <li v-for="error in nonFieldErrors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <v-autocomplete
            v-if="hasMeetingChoices"
            v-model="meeting"
            :items="meetingChoices"
            :label="$t('Business_case_meeting')"
            :rules="[$rules.required]"
            item-text="title"
            item-value="id"
            return-object
            required
            clearable
            :error-messages="errorsForField('meeting')"
          />
          <v-autocomplete
            v-model="vorstoss.business_type"
            :items="businessTypeChoices"
            :label="$t('Business_type')"
            :rules="[$rules.required]"
            return-object
            required
            clearable
            :error-messages="errorsForField('business_type')"
          />
          <v-text-field
            v-model="vorstoss.title"
            :label="$t('Business_case_title')"
            :rules="[$rules.required]"
            required
            :error-messages="errorsForField('title')"
          />
          <v-textarea
            v-model="vorstoss.description"
            :label="$t('Wording')"
            :rules="[$rules.required]"
            required
            :error-messages="errorsForField('description')"
          />
          <v-textarea v-model="vorstoss.reason" :label="$t('Business_case_reason')" />
          <v-autocomplete
            v-model="firstSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_proposal_creator')"
            :rules="[$rules.required]"
            required
            :disabled="!user.can_create_proposals_for_other_people"
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="secondSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_second_signature')"
            clearable
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="thirdSignatory"
            :items="committeeMembers"
            :label="$t('Business_case_third_signature')"
            clearable
            item-value="id"
            :item-text="signatoryLabel"
            return-object
          />
          <v-autocomplete
            v-model="otherSignatories"
            :items="otherSignatoryChoices"
            :label="$t('Business_case_other_signature')"
            clearable
            item-value="id"
            :item-text="otherSignatoryLabel"
            return-object
            multiple
          />
          <v-select
            v-model="vorstoss.rights_holder"
            :items="rightsHolderOptions"
            :label="$t('Business_case_rights_holder')"
            clearable
          />
          <div class="d-flex">
            <v-checkbox v-model="vorstoss.is_urgent" class="mr-3" :label="$t('mark_urgent')" />
          </div>

          <v-file-input
            v-model="attachments"
            small-chips
            multiple
            :label="$t('Select_file')"
            @change="handleFileChange"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="error" @click="cancel">{{ $t('Cancel') }}</v-btn>
          <v-btn text type="submit" data-testid="save-vorstoss" color="primary">{{ saveActionLabel }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import Profile from '@/store/models/profile'
import Meeting from '@/store/models/meeting'
import Committee from '@/store/models/committee'
import find from 'lodash/find'
import User from '@/store/models/user'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import isUndefined from 'lodash/isUndefined'

function vorstossFactory() {
  return { signatories: [{ id: Profile.query().first().id, role: 'FIRST_SIGNATORY', type: 'user' }] }
}

function memberLabel(member) {
  return [member.first_name, member.last_name].filter(Boolean).join(' ') + ` (${member.username})`
}

export default {
  name: 'VorstossDialog',
  data() {
    return {
      isOpen: false,
      vorstoss: {},
      valid: null,
      rightsHolderOptions: [
        { text: this.$t('Business_case_proposal_creator'), value: 'proposal_creator' },
        { text: this.$t('Business_case_faction'), value: 'faction' },
        { text: this.$t('Business_case_commission'), value: 'commission' },
        { text: this.$t('Business_case_cross_party'), value: 'cross_party' },
      ],
      errors: {},
      businessTypeChoices: [],
      meetingChoices: [],
      meeting: {},
      attachments: [],
      isAttachmentChanged: false,
      otherSignatoryTypes: {
        user: this.$t('member'),
        committee: this.$t('Committee'),
        committeegroup: this.$t('Committee_Group'),
      },
      committeeGroups: [],
      loadingAsyncData: true,
    }
  },
  computed: {
    committeeMembers() {
      return User.all().filter((user) => user.roles.includes('member'))
    },
    otherSignatoryChoices() {
      const members = this.committeeMembers.map((member) => ({
        id: member.id,
        label: memberLabel(member),
        type: 'user',
      }))
      const committees = Committee.all().map((committee) => ({
        id: committee.id,
        label: committee.title,
        type: 'committee',
      }))
      const committeeGroups = this.committeeGroups.map((committeeGroup) => ({
        id: committeeGroup.id,
        label: committeeGroup.title,
        type: 'committeegroup',
      }))
      return [...committees, ...committeeGroups, ...members]
    },
    user() {
      return Profile.query().first()
    },
    firstSignatory: {
      get() {
        return this.getSignatory('FIRST_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'FIRST_SIGNATORY')
      },
    },
    secondSignatory: {
      get() {
        return this.getSignatory('SECOND_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'SECOND_SIGNATORY')
      },
    },
    thirdSignatory: {
      get() {
        return this.getSignatory('THIRD_SIGNATORY')
      },
      set(signatory) {
        this.setSignatory(signatory, 'THIRD_SIGNATORY')
      },
    },
    otherSignatories: {
      get() {
        return filter(this.vorstoss.signatories, { role: 'OTHER_SIGNATORY' })
      },
      set(signatories) {
        this.vorstoss.signatories = [
          ...signatories.map((signatory) => ({ id: signatory.id, role: 'OTHER_SIGNATORY', type: signatory.type })),
          ...this.vorstoss.signatories.filter((signatory) => signatory.role !== 'OTHER_SIGNATORY'),
        ]
      },
    },
    nonFieldErrors() {
      return this.errorsForField('non_field_errors')
    },
    hasNonFieldErrors() {
      return !isEmpty(this.nonFieldErrors)
    },
    isInEditMode() {
      return !isUndefined(this.vorstoss.id)
    },
    dialogTitle() {
      return this.isInEditMode ? this.$t('updateVorstossTitle') : this.$t('createVorstossTitle')
    },
    saveActionLabel() {
      return this.isInEditMode ? this.$t('modifyVorstossButton') : this.$t('newVorstossButton')
    },
    saveSuccessMessage() {
      return this.isInEditMode ? this.$t('vorstossCardModifySuccess') : this.$t('vorstossCardCreateSuccess')
    },
    saveErrorMessage() {
      return this.isInEditMode ? this.$t('vorstossCardModifyError') : this.$t('vorstossCardCreateError')
    },
    hasMeetingChoices() {
      return !isEmpty(this.meetingChoices)
    },
  },
  async mounted() {
    try {
      const businessTypes = await api.get(createLink('businesstypes'))
      this.businessTypeChoices = businessTypes.data.map((businesstype) => ({
        text: businesstype.title,
        value: businesstype.api_uid,
      }))
    } finally {
      this.loadingAsyncData = false
    }
  },

  methods: {
    handleFileChange(files) {
      this.attachments = files
      this.isAttachmentChanged = true
    },
    signatoryLabel(signatory) {
      return memberLabel(signatory)
    },
    otherSignatoryLabel(signatory) {
      const signatoryType = get(this.otherSignatoryTypes, signatory.type, 'unknown')
      return `${signatory.label} - ${signatoryType}`
    },
    getSignatory(role) {
      return find(this.vorstoss.signatories, { role })
    },
    setSignatory(signatory, role) {
      if (signatory === null) {
        this.vorstoss.signatories = this.vorstoss.signatories.filter(
          (currentSignatory) => currentSignatory.role !== role,
        )
      } else {
        this.vorstoss.signatories = [
          { id: signatory.id, role, type: 'user' },
          ...this.vorstoss.signatories.filter((currentSignatory) => currentSignatory.role !== role),
        ]
      }
    },

    cancel() {
      this.$refs.form.resetValidation()
      this.isOpen = false
    },
    errorsForField(field) {
      return get(this.errors, field, [])
    },
    async attachAttachments(vorstoss) {
      const formData = new FormData()
      this.attachments.forEach((attachment) => formData.append('attachments', attachment))
      await api.patch(createLink('vorstoss/{id}/attach_attachments', { id: vorstoss.id }), formData)
    },
    async save() {
      this.vorstoss.signatories = this.vorstoss.signatories.filter((signatory) => signatory.id)
      try {
        const { data: vorstoss } = this.isInEditMode
          ? await api.patch(createLink('vorstoss/{id}/', { id: this.vorstoss.id }), this.vorstoss)
          : await api.post(createLink('vorstoss/'), { meeting: this.meeting.id, ...this.vorstoss })

        if (this.isAttachmentChanged) {
          await this.attachAttachments(vorstoss)
        }

        this.$emit('update')
        this.notifySuccess(this.saveSuccessMessage)
        this.cancel()
      } catch (error) {
        this.errors = get(error, 'response.data')
        this.notifyError(this.saveErrorMessage)
        throw error
      }
    },
    async openWithCommittee(committee) {
      await User.fetchAllByModel(committee)
      await Meeting.fetchAllByModel(committee)
      this.meetingChoices = Meeting.query().where('committee_id', committee.id).all()
      this.vorstoss = vorstossFactory()
      this.isOpen = true
    },
    async openWithMeeting(meeting) {
      this.meeting = meeting
      const committee = await Committee.findOrFetch(meeting.committee_id)
      await User.fetchAllByModel(committee)
      this.vorstoss = vorstossFactory()
      const { data: committeeGroups } = await api.get(
        createLink('committees/{id}/committee_groups', { id: committee.id }),
      )
      this.committeeGroups = committeeGroups
      this.isOpen = true
    },
    async openWithVorstoss(vorstoss) {
      this.meeting = await Meeting.findOrFetch(vorstoss.meeting_id)
      await Committee.fetchAll()
      const committee = await Committee.query().find(this.meeting.committee_id)
      await User.fetchAllByModel(committee)
      this.vorstoss = vorstoss

      this.attachments = vorstoss.attachments
      // Fetch committee groups
      const { data: committeeGroups } = await api.get(
        createLink('committees/{id}/committee_groups', { id: committee.id }),
      )
      this.committeeGroups = committeeGroups
      this.isOpen = true
    },
  },
}
</script>
